import { navigate } from 'gatsby';
import { useTranslation } from 'react-i18next';
import React, { memo, useContext, useEffect, useState } from 'react';
import BaseModal from './BaseModal';
import Button from '../components/shared/Button';
import ModalContext from '../contexts/ModalContext';
import UserContext from '../contexts/UserContext';

const AuthModal = () => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const [isLoadingGoogle, setLoadingGoogle] = useState(false);

  const { emitter, events } = useContext(ModalContext);
  const { user, loginWithGoogle, logout } = useContext(UserContext);

  useEffect(() => {
    const unbind = emitter.on(events.AUTH_MODAL, () => setOpen(true));

    return () => unbind();
  }, [emitter, events]);

  const handleSignInWithGoogle = async () => {
    setLoadingGoogle(true);
    await loginWithGoogle();
    setLoadingGoogle(false);
  };

  const handleGotoApp = () => {
    navigate('/app/dashboard');
    setOpen(false);
  };
  const handleGotoAdmin = () => {
    navigate('/app/adminboard');
    setOpen(false);
  };
  const handleGotoSuperAdmin = () => {
    navigate('/app/superAdminboard');
    setOpen(false);
  };

  const getTitle = () =>
    user
      ? t('modals.auth.welcome', { name: user.displayName || 'Agent 47' })
      : t('modals.auth.whoAreYou');

  const getMessage = () =>
    user ? t('modals.auth.loggedInText') : t('modals.auth.loggedOutText');

  const loggedInAction = (
    <>
      <Button outline className="mr-8" onClick={logout}>
        {t('shared.buttons.logout')}
      </Button>
      <Button title="" onClick={handleGotoApp}>
        {t('landing.hero.goToApp')}
      </Button>
      {user && (user.isAdmin || user.isSuperAdmin) && (
        <Button title="" className="ml-8" onClick={handleGotoAdmin}>
          {t('landing.hero.goToAdmin')}
        </Button>
      )}
      {user && user.isSuperAdmin && (
        <Button title="" className="ml-8" onClick={handleGotoSuperAdmin}>
          {t('landing.hero.goToSuperAdmin')}
        </Button>
      )}
    </>
  );

  const loggedOutAction = (
    <div className="flex">
      <Button isLoading={isLoadingGoogle} onClick={handleSignInWithGoogle}>
        {t('modals.auth.buttons.google')}
      </Button>
    </div>
  );

  return (
    <BaseModal
      title={getTitle()}
      state={[open, setOpen]}
      action={user ? loggedInAction : loggedOutAction}
    >
      <p className="leading-loose">{getMessage()}</p>
    </BaseModal>
  );
};

export default memo(AuthModal);
