import React, { memo, useContext, useEffect, useState } from 'react';
import ModalContext from '@/contexts/ModalContext';
import Autocomplete from '@material-ui/lab/Autocomplete';
import TextField from '@material-ui/core/TextField';
import Button from '@/components/shared/Button';
import { withStyles } from '@material-ui/core/styles';
import { toast } from 'react-toastify';
import DatabaseContext from '../contexts/DatabaseContext';
import ModalEvents from '../constants/ModalEvents';
import BaseModal from './BaseModal';

const ManageVisibleToAdminToggleButtonModal = () => {
  const { updateUserInfo } = useContext(DatabaseContext);

  const [users, setUsers] = useState(null);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState(null);
  const [loading, setLoading] = useState(false);

  const { emitter } = useContext(ModalContext);
  const event =
    ModalEvents.UPDATE_USER_PERMISSION_OF_VISIBLE_TO_ADMIN_MENU_MODAL;

  useEffect(() => {
    const unbind = emitter.on(event, (payload) => {
      setOpen(true);
      setUsers(payload);
    });

    return () => unbind();
  }, [emitter, event]);

  const modifyVisibleOfVisibleToAmdinMenu = async () => {
    setLoading(true);
    const userInfo = value;
    userInfo.isVisibleOfVisibleToAmdinMenu = true;
    await updateUserInfo(userInfo);
    setLoading(false);
    setOpen(false);
    setValue(null);
    toast.success('Now please inform the person to enable the toggle button!');
  };

  const action = (
    <>
      <Button
        className="mr-8"
        isLoading={loading}
        onClick={modifyVisibleOfVisibleToAmdinMenu}
      >
        OK
      </Button>
    </>
  );

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };

  const CssTextField = withStyles({
    root: {
      '& label.Mui-focused': {
        color: 'var(--color-primary-500)',
      },
      '& .MuiInput-underline:after': {
        borderBottomColor: 'var(--color-primary-500)',
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: 'var(--color-primary-900)',
          color: 'red',
        },
        '&:hover fieldset': {
          borderColor: 'var(--color-primary-500)',
          color: 'red',
        },
        '&.Mui-focused fieldset': {
          borderColor: 'var(--color-primary-500)',
          color: 'red',
        },
        '& .MuiOutlinedInput-input': {
          color: 'white',
        },
      },
    },
  })(TextField);

  return (
    <BaseModal
      title="Manage VisibleToAdmin Toggle Button"
      state={[open, setOpen]}
      action={action}
    >
      <Autocomplete
        options={users}
        getOptionLabel={(option) => `${option.email}--(${option.displayName})`}
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <CssTextField {...params} variant="outlined" />
        )}
      />
    </BaseModal>
  );
};

export default memo(ManageVisibleToAdminToggleButtonModal);
